import { ColumnFilter, ColumnSort, PaginationState } from '@tanstack/react-table'
import { FieldPath } from 'react-hook-form/dist/types/path'
import { UseFormSetError } from 'react-hook-form'
import { isNumeric } from './Helper'
import { snakeCase } from 'lodash-es'
import ApiQueryBuilder from './api-query-builder/ApiQueryBuilder'
import axios, { AxiosError } from 'axios'

export const handleFormErrorsFromAxios = <RequestData extends object>(
    axiosError: AxiosError<{ message: string; errors?: object }>,
    setError: UseFormSetError<RequestData>
) => {
    return (
        axiosError?.response?.data?.errors &&
        Object.entries(axiosError.response.data?.errors).forEach(([key, value]) => {
            return setError(key as FieldPath<RequestData>, {
                message: value as string,
                type: 'server'
            })
        })
    )
}

export type PaginationApiQueryBuilderParams = {
    filters?: ColumnFilter[]
    sorts?: ColumnSort[]
    filterMapping?: Record<string, string>
    sortMapping?: Record<string, string>
    pagination?: PaginationState
    fields?: string[]
}

export type FilterType = 'string' | 'number' | 'date' | 'boolean' | 'num-boolean'

export type NumberBetweenFilter = {
    from?: number
    to?: number
}

export type DateBetweenFilter = {
    from?: string
    to?: string
}

type FilterDataType = {
    string: string
    number: NumberBetweenFilter
    date: DateBetweenFilter
    boolean: boolean
    'num-boolean': number
}

export type Filter<
    Type extends FilterType = 'string',
    IsArray extends boolean = false,
    Value = IsArray extends true ? FilterDataType[Type][] : FilterDataType[Type]
> = {
    type: Type
    value?: Value
}

export const makePaginationApiQueryBuilder = ({
    filters: filtersProp,
    filterMapping,
    sorts: sortsProp,
    sortMapping,
    pagination,
    fields
}: PaginationApiQueryBuilderParams) => {
    let filters = filtersProp
    let sorts = sortsProp

    if (filterMapping) {
        filters = filtersProp.map(filter => {
            const newId = filterMapping[filter.id]

            if (!newId) {
                return filter
            }

            return {
                ...filter,
                id: newId
            }
        })
    }

    if (sortMapping) {
        sorts = sortsProp.map(sort => {
            const newId = sortMapping[sort.id]

            if (!newId) {
                return sort
            }

            return {
                ...sort,
                id: newId
            }
        })
    }

    const query = new ApiQueryBuilder()

    // Apply page
    query.page(pagination.pageIndex + 1).perPage(pagination.pageSize)

    // Apply filters
    filters.forEach(item => {
        const baseFilter = item.value as Filter
        if (!baseFilter) {
            return
        }

        const id = item.id.includes('.') ? item.id : snakeCase(item.id)
        const type = baseFilter.type as FilterType

        if (
            typeof item.value === 'undefined' ||
            typeof baseFilter.value === 'undefined' ||
            (type === 'string' && !baseFilter.value.length)
        ) {
            return
        }

        if (Array.isArray(baseFilter.value)) {
            const filter = baseFilter as unknown as Filter<'string', true>
            query.whereIn(id, filter.value)
            return
        }
        if (type === 'number') {
            const filter = baseFilter as unknown as Filter<'number'>
            if (isNumeric(filter.value.from)) {
                query.fromNumber(id, parseInt(`${filter.value.from}`))
            }
            if (isNumeric(filter.value.to)) {
                query.toNumber(id, parseInt(`${filter.value.to}`))
            }
            return
        }
        if (type === 'date') {
            const filter = baseFilter as unknown as Filter<'date'>
            if (typeof filter.value.from !== 'undefined') {
                query.afterDate(id, filter.value.from)
            }
            if (typeof filter.value.to !== 'undefined') {
                query.beforeDate(id, filter.value.to)
            }
            return
        }
        query.where(id, baseFilter.value)
    })

    // Apply sorting
    query.sort(
        ...sorts.map(item => {
            return `${item.desc ? '-' : ''}${item.id}`
        })
    )

    // Apply fields
    if (fields && !!fields.length) {
        query.select(fields)
    }

    return query
}

export type ApiQueryBuilderParams = {
    filters?: ColumnFilter[]
    sorts?: ColumnSort[]
    filterMapping?: Record<string, string>
    sortMapping?: Record<string, string>
}

export const makeApiQueryBuilder = ({
    filters: filtersProp = [],
    filterMapping,
    sorts: sortsProp = [],
    sortMapping
}: ApiQueryBuilderParams) => {
    let filters = filtersProp
    let sorts = sortsProp

    if (filterMapping) {
        filters = filtersProp.map(filter => {
            const newId = filterMapping[filter.id]

            if (!newId) {
                return filter
            }

            return {
                ...filter,
                id: newId
            }
        })
    }

    if (sortMapping) {
        sorts = sortsProp.map(sort => {
            const newId = sortMapping[sort.id]

            if (!newId) {
                return sort
            }

            return {
                ...sort,
                id: newId
            }
        })
    }

    const query = new ApiQueryBuilder()

    // Apply filters
    filters.forEach(item => {
        const baseFilter = item.value as Filter
        if (!baseFilter) {
            return
        }

        const id = item.id.includes('.') ? item.id : snakeCase(item.id)
        const type = baseFilter?.type as FilterType

        if (
            typeof item.value === 'undefined' ||
            typeof baseFilter?.value === 'undefined' ||
            (type === 'string' && !baseFilter?.value?.length)
        ) {
            return
        }

        if (Array.isArray(baseFilter.value)) {
            const filter = baseFilter as unknown as Filter<'string', true>
            query.whereIn(id, filter.value)
            return
        }
        if (type === 'number') {
            const filter = baseFilter as unknown as Filter<'number'>
            if (isNumeric(filter.value.from)) {
                query.fromNumber(id, parseInt(`${filter.value.from}`))
            }
            if (isNumeric(filter.value.to)) {
                query.toNumber(id, parseInt(`${filter.value.to}`))
            }
            return
        }
        if (type === 'date') {
            const filter = baseFilter as unknown as Filter<'date'>
            if (typeof filter.value.from !== 'undefined') {
                query.afterDate(id, filter.value.from)
            }
            if (typeof filter.value.to !== 'undefined') {
                query.beforeDate(id, filter.value.to)
            }
            return
        }
        query.where(id, baseFilter.value)
    })

    // Apply sorting dynamically
    query.sort(...sorts.map(item => `${item.desc ? '-' : ''}${item.id}`))

    return query
}

export type ApiQueryFilterBuilderParams = {
    filters?: { id: string; value: any }[]
    filterMapping?: Record<string, string>
}

export const makeApiQueryFilterBuilder = ({
    filters: filtersProp = [],
    filterMapping
}: ApiQueryFilterBuilderParams) => {
    let filters = filtersProp

    if (filterMapping) {
        filters = filtersProp.map(filter => ({
            id: filterMapping[filter.id] || filter.id,
            value: filter.value
        }))
    }

    const query = new ApiQueryBuilder()

    // Apply filters
    filters.forEach(({ id, value }) => {
        id = id.includes('.') ? id : snakeCase(id)

        if (typeof value === 'undefined' || (typeof value === 'string' && !value.length)) {
            return
        }

        if (Array.isArray(value)) {
            query.whereIn(id, value)
            return
        }
        query.where(id, value)
    })

    return query
}

export const getFieldErrors = (error: Error, fieldName: string): string[] => {
    if (!axios.isAxiosError(error)) {
        return []
    }
    return error?.response?.data?.errors?.[fieldName] || []
}
